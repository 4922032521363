var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-lg-6",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","lg":"10","xl":"8"}},[_c('v-data-table',{staticClass:"elevation-1 pa-3",attrs:{"headers":_vm.headers,"loading-text":"Завантаження...","no-data-text":"Нема даних для відображення","items":_vm.districts,"options":_vm.options,"loading":_vm.loading,"footer-props":_vm.footer,"focusable":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":_vm.$vuetify.theme.dark ? 'background2' : 'white'}},[_c('v-toolbar-title',[_c('h2',{staticClass:"mr-6"},[_vm._v("Дільниці")])]),_c('v-spacer'),_c('AddDistrictModal',{ref:"addDisrict",attrs:{"user":_vm.user,"operators_groups":_vm.operators_groups}})],1)]},proxy:true},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.districts.indexOf(item) + 1))])]}},{key:"item.operator_group",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.operators_groups.find(function (x) { return x.id == item.operator_group; }).name)+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"title":"Редагувати","icon":""},on:{"click":function($event){return _vm.$refs.addDisrict.show(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"title":"Видалити","icon":""},on:{"click":function($event){return _vm.$refs.confirmDeleteDistrict.show(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" із "+_vm._s(itemsLength)+" ")]}}])})],1)],1),_c('ModalConfirmDeleteDistrict',{ref:"confirmDeleteDistrict"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }