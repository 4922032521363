var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-lg-6",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","lg":"11","xl":"9"}},[_c('v-data-table',{staticClass:"elevation-1 pa-3",attrs:{"headers":_vm.headers,"loading-text":"Завантаження...","no-data-text":"Нема данних для відображення","items":_vm.users,"options":_vm.options,"loading":_vm.loading,"footer-props":_vm.footer,"focusable":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":_vm.$vuetify.theme.dark ? 'background2' : _vm.white}},[_c('v-toolbar-title',{on:{"click":function($event){return _vm.checkGetAbonents()}}},[_c('h2',{staticClass:"mr-6"},[_vm._v("Користувачі")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.users.indexOf(item) + 1))])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"readonly":"","loading":_vm.loading_switch && _vm.loading_switch_id == item.profile_id},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.user && _vm.user.id != item.id && _vm.offOnUser(item)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.first_name) + " " + (item.last_name))))])]}},{key:"item.user_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.users_type.find(function (x) { return x.id == [item.user_type]; }).name))])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"title":"Редагувати","disabled":_vm.user && _vm.user.id == item.id,"icon":""},on:{"click":function($event){return _vm.$refs.changeUserDialog.show(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" із "+_vm._s(itemsLength)+" ")]}}])})],1)],1),_c('ChangeUserModal',{ref:"changeUserDialog",on:{"add-change-user":function($event){_vm.updateTable += 1}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }