var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-lg-6",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","lg":"10","xl":"8"}},[_c('v-data-table',{staticClass:"elevation-1 pa-3",attrs:{"headers":_vm.headers,"loading-text":"Завантаження...","no-data-text":"Нема даних для відображення","items":_vm.is_order_tags && _vm.order_tags || _vm.tags,"options":_vm.options,"loading":_vm.loading,"footer-props":_vm.footer,"focusable":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":_vm.$vuetify.theme.dark ? 'background2' : 'white'}},[_c('v-toolbar-title',[_c('h2',{staticClass:"mr-6"},[_vm._v("Теги "+_vm._s(_vm.is_order_tags && ' Заявок'))])]),_c('v-spacer'),(_vm.user && _vm.user.operator_id == 1)?_c('AddTagModal',{ref:"addTag",attrs:{"user":_vm.user,"is_order_tags":_vm.is_order_tags}}):_vm._e()],1)]},proxy:true},{key:"item.tag",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" mdi-tag ")])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.user && _vm.user.operator_id != 1,"title":"Редагувати","icon":""},on:{"click":function($event){return _vm.$refs.addTag.show(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"disabled":_vm.user && _vm.user.operator_id != 1,"title":"Видалити","icon":""},on:{"click":function($event){return _vm.$refs.confirmDeleteTag.show(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" із "+_vm._s(itemsLength)+" ")]}}])})],1)],1),_c('ModalConfirmDeleteTag',{ref:"confirmDeleteTag",attrs:{"is_order_tags":_vm.is_order_tags}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }