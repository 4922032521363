<template>
	<div class="add-order-modal">
        
        <v-dialog v-model="dialog" persistent max-width="400">
            <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    v-on="on"
                >
                    <v-icon left>mdi-plus</v-icon>
                    Додати
                </v-btn>
            </template>
            <v-card>
            <v-card-title>
            <span class="headline">{{ userData.title }} </span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                        <v-col cols="12" class="py-2">
                        <v-btn-toggle
                            v-model="form_data.color"
                            dense
                            color="primary accent-3"
                            group
                            class="d-flex justify-center"
                        >
                        <template v-for="(color, index) in colors">
                            <v-btn icon :value="color" :key="index">
                                <v-icon :color="color">mdi-tag</v-icon>
                            </v-btn>
                        </template>
                        </v-btn-toggle>
                        <v-btn-toggle
                            v-model="form_data.color"
                            dense
                            color="primary accent-3"
                            group
                            class="d-flex justify-center"
                        >
                        <template v-for="(color, index2) in colors2">
                            <v-btn icon :value="color" :key="index2">
                                <v-icon :color="color">mdi-tag</v-icon>
                            </v-btn>
                        </template>
                        </v-btn-toggle>
                    </v-col>
                    <!--<v-col cols="12">
                        <v-text-field 
                        label="Іконка"
                        v-model="form_data.icon"
                        name="name"
                        type="text"
                        hide-details="auto"
                        append-icon="mdi-magnify"
                        @click:append="openSiteIcon"
                        >
                        
                        </v-text-field>
                    </v-col>-->
                    <v-col cols="12">
                        <v-text-field 
                        label="Назва"
                        v-model="form_data.name"
                        name="name"
                        :rules="textRules"
                        type="text"
                        hide-details="auto"
                        >
                        </v-text-field>
                    </v-col>
                    

                    
                    
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer></v-spacer>

                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">
                    <template v-if="changeForm">Зберегти</template>
                    <template v-else>Додати</template>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
			
	</div>
</template>

<script>

    import axios from "axios"
	export default {
        name: 'add-district-modal',
        props: ["user", "operators_groups", "is_order_tags"],
		data: () => ({
            changeForm: false,
            loadingSubmit:false,
            item: {},
            userData: {},
            defaultUserData: {
                add: {title: "Додавання Тегу", method: "POST", readonly: false, message: "Тег створено!"},
                change: {title: "Редагування Тегу", method: "PATCH", readonly: true, message: "Данні тегу змінено!"}
            },
            dialog: false,
            valid: true,
            form_data:{
                
            },
            colors: [
                'deep-orange',
                'primary',
                'orange',
                'green',
                'pink',
                'grey darken-4',
                
            ],
            colors2: [
                'red darken-3',
                'grey',
                'blue',
                'yellow',
                'brown',
                'deep-purple',
                
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            emailRules: [
                v => !v && true || /.+@.+\..+/.test(v) || 'E-mail не валідний',
            ],
            
        }),
        mounted(){
            this.userData = JSON.parse(JSON.stringify(this.defaultUserData.add));

        },
        methods: {
            show(item){
                this.changeForm = true
                this.userData = JSON.parse(JSON.stringify(this.defaultUserData.change));
                this.form_data = JSON.parse(JSON.stringify(item));
                this.form_data.color = item.color
                this.dialog = true;
            },
            validate () {
                if (this.$refs.form.validate()) {
                this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.form_data.color = ''
                this.changeForm = false
                this.userData = JSON.parse(JSON.stringify(this.defaultUserData.add));
            },
            sendForm() {
                //console.log(this.form_data);
                
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    let url = this.is_order_tags && '/api/service/order_tag/' || '/api/cabletv/user_tag/'
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: this.userData.method,
                        url: `${url}${this.changeForm && this.form_data.id + '/' || ''}`,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.loadingSubmit = false;
                        if(this.is_order_tags) this.$store.dispatch('tags/fetchOrderTags')
                        else this.$store.dispatch('tags/fetchTags')
                        this.$router.app.$snack.show(this.userData.message);
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.loadingSubmit = false;
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    });
                }
                
            },
            openSiteIcon(){
                window.open('https://pictogrammers.com/library/mdi/', '_blank')
            }
        }
    }
</script>