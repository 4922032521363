<template>
  
  <v-container >
    <v-row justify="center" class="mt-lg-6">
      <v-col  md="12" lg="10" xl="8">
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема даних для відображення"
          :items="is_order_tags && order_tags || tags"
          :options.sync="options"
          :loading="loading"
          :footer-props="footer"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>

            <v-toolbar flat :color="$vuetify.theme.dark ? 'background2' : 'white'">
              <v-toolbar-title><h2 class="mr-6">Теги {{ is_order_tags && ' Заявок' }}</h2></v-toolbar-title>
            
              <v-spacer></v-spacer>
              
              <AddTagModal v-if="user && user.operator_id == 1" ref="addTag" :user="user" :is_order_tags="is_order_tags"/>

            </v-toolbar>
            
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        
        <template #item.tag="{ item }">
          <v-icon :color="item.color">
            mdi-tag
          </v-icon>
        </template>
        <template #item.options="{ item }">
          <v-btn :disabled="user && user.operator_id != 1" title="Редагувати" icon  @click="$refs.addTag.show(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn :disabled="user && user.operator_id != 1" title="Видалити" icon @click="$refs.confirmDeleteTag.show(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
        </template>
        </v-data-table>
      </v-col>
      </v-row>
      <ModalConfirmDeleteTag ref="confirmDeleteTag" :is_order_tags="is_order_tags"/>
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex"
  export default {
    name: "table-tags",
    props: ["user", "is_order_tags"],
    comments:{
    },
    data: () => ({
      updateTable: 0,
      loading: false,
      options: {},
      headers: [
        { text: "Назва", value: 'name', sortable: false, },
        { text: "Тег", value: 'tag', sortable: false, },
        { text: 'Опції', value: 'options', sortable: false, }, 
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    computed: {
      ...mapGetters({
          tags: 'tags/tags',
          order_tags: 'tags/order_tags',
        }),
    },
    mounted(){
      
    },
    watch: {
      options: {
        handler () {
          this.getTags();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getTags();
      },
    },
    methods: {
      getTags(){
        console.log()
        if(this.is_order_tags) this.$store.dispatch('tags/fetchOrderTags');
        else this.$store.dispatch('tags/fetchTags');
      },
    },
  }
</script>