<template>
  
  <v-container >
    <v-row justify="center" class="mt-lg-6">
      <v-col  md="12" lg="11" xl="9">
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          :items="users"
          :options.sync="options"
          :loading="loading"
          :footer-props="footer"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>

            <v-toolbar flat :color="$vuetify.theme.dark ? 'background2' : white">
              <v-toolbar-title @click="checkGetAbonents()" class=""><h2 class="mr-6">Користувачі</h2></v-toolbar-title>
            
              <v-spacer></v-spacer>


            </v-toolbar>
            
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ users.indexOf(item) + 1 }}</span>
        </template>
        <template #item.is_active="{ item }">
          <v-switch
          readonly :loading="loading_switch && loading_switch_id == item.profile_id" v-model="item.is_active" @click.stop.prevent="user && user.id != item.id && offOnUser(item)"
          ></v-switch>
        </template>
        <template #item.name="{ item }">
          <span>{{ `${item.first_name} ${item.last_name}` }}</span>
        </template>
        <template #item.user_type="{ item }">
          <span>{{ users_type.find(x => x.id == [item.user_type]).name }}</span> 
        </template>
        <template #item.options="{ item }">
          <v-btn title="Редагувати" :disabled="user && user.id == item.id" icon  @click="$refs.changeUserDialog.show(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
        </template>
        </v-data-table>
      </v-col>
      </v-row>
      <ChangeUserModal ref="changeUserDialog" v-on:add-change-user="updateTable += 1"/>
  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  import ChangeUserModal from '@/components/OperatorAdmin/ChangeUserModal'
  export default {
    name: "table-user",
    components: {
      ChangeUserModal
    },
    props: ["user"],
    data: () => ({
      loading_switch: false,
      updateTable: 0,
      loading: false,
      options: {},
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Логин', value: 'username', sortable: false, },
        { text: 'Статус', value: 'is_active' },
        { text: "Ім'я", value: 'name', sortable: false, },
        { text: 'Тип', value: 'user_type', },
        { text: 'Телефон', value: 'phone', sortable: false, },
        { text: 'Колл-Центр', value: 'call_centre', sortable: false, },
        { text: 'Опції', value: 'options', sortable: false, }, 
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    computed: {
        ...mapGetters({
        users_type: "users/users_type",
        users: "users/users_full"
        }),
    },
    watch: {
      options: {
        handler () {
          this.getUsers();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getUsers();
      },
    },
    methods: {
      getUsers(){
        this.$store.dispatch('users/fetchUsersFull');
      },
      offOnUser(user){
                this.loading_switch = true;
                this.loading_switch_id = user.profile_id
                axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PUT",
                        url: `/api/user/change_status_type/${user.profile_id}/`,
                        data: {active: !user.is_active},
                    })
                    .then(() => {
                        //console.log(response);
                        this.getUsers()
                        this.$store.dispatch('users/fetchUsers')
                        this.$router.app.$snack.show(`Користувача ${user.is_active && 'Вимкнено!' || 'Увімкнено!'}`);
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loading_switch = false, this.loading_switch_id = null))
            },
    },
  }
</script>