<template>     
  <v-dialog v-model="dialog" persistent max-width="330">      
    <v-card>
      <v-card-title class="text-h5 text-center">
        Видалити тег?
      </v-card-title>
      <v-card-text class="text-center">Ви впевнені, що хочете видалити тег?</v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-orange darken-1"
          text
          @click="closeDialog()"
        >
          Ні
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary lighten-1"
          text
          @click="sendForm"
        >
          Так
        </v-btn>
      </v-card-actions>
    </v-card>   
  </v-dialog>
</template>

<script>
    import axios from "axios"
    //import { mapGetters } from "vuex"
	export default {
    name: 'modal-confirm-delete-tag',
    props: ["is_order_tags"],
		data: () => ({
            loading:false,
            dialog: false,
            district: {},
        }),
        methods: {
            show(tag){
              this.tag = tag
              this.dialog = true;

            },
            closeDialog (){
              this.tag = {}
                this.dialog = false
            },
            sendForm() {
              this.loading = true;
              let url = this.is_order_tags && '/api/service/order_tag/' || '/api/cabletv/user_tag/'
              axios({
                  method: "delete",
                  url: `${url}${this.tag?.id}/`,
              })
              .then(response => {
              if(response){
                if(this.is_order_tags) this.$store.dispatch('tags/fetchOrderTags')
                else this.$store.dispatch('tags/fetchTags')
                this.$router.app.$snack.show("Тег видалено")
                this.closeDialog()
              }else{
                  this.$router.app.$snack.show("Помилка видалення тегу", 'deep-orange')
              }
              })
              .catch(err => {
              //console.log(err);
                  this.$router.app.$sheet.show("Помилка!", err);
              })
              .finally(() => ( this.loading = false ))
                
            },
        }
    }
</script>