<template>
	<div class="">
        
        <v-dialog v-model="dialog" persistent max-width="400">
            
            <v-card>
            <v-card-title>
            <span class="headline">{{ userData.title }} <span v-if="form_data.username && changeForm">"{{ form_data.username }}"</span></span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                   <!-- <v-col cols="12" sm="6" md="6">
                        <v-text-field 
                        label="Ім'я"
                        v-model="form_data.first_name"
                        name="first_name"
                        :rules="textRules"
                        type="text"
                        disabled
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field 
                        label="Прізвище"
                        v-model="form_data.last_name"
                        name="last_name"
                        :rules="textRules"
                        type="text"
                        disabled
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        label="Телефон"
                        v-model="form_data.phone"
                        name="phone"
                        type="phone"
                        disabled
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        label="Колл-Центр"
                        v-model="form_data.call_centre"
                        name="call_centre"
                        type="phone"
                        disabled
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        label="E-mail"
                        v-model="form_data.email"
                        :rules="emailRules"
                        name="email"
                        type="email"
                        disabled
                        >
                        </v-text-field>
                    </v-col>
                    
                
                    <v-col cols="12" >
                        <v-text-field
                        label="Логін"
                        v-model="form_data.username"
                        :rules="textRules"
                        name="username"
                        type="text"
                        disabled
                        >
                        </v-text-field>
                    </v-col>
                    -->
                    <v-col cols="12" >
                        <v-select
                        v-model="form_data.user_type"
                        :rules="textRules"
                        :items="user_type_custom"
                        label="Тип"
                        item-text="name"
                        item-value="id"
                        ></v-select>
                    </v-col>
                    
                    
                    
                    
                    
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer></v-spacer>
                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Зберегти</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	</div>
</template>

<script>

    import axios from "axios"
    import { mapGetters } from "vuex"
	export default {
        name: 'change-user-modal',
		data: () => ({
            loadingSubmit:false,
            changeForm: false,
            item: {},
            userData: {},
            defaultUserData: {
                add: {title: "Додати Користувача", method: "POST", readonly: false, message: "Користувача створено!"},
                change: {title: "Користувач", method: "PUT", readonly: true, message: "Данні користувача змінено!"}
            },
            dialog: false,
            valid: true,
            form_data:{
                first_name: "",
                last_name: "",
                password: "",
                username: "",
                email: "",
                user_type: ""
            },
            
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            emailRules: [
                v => !v && true || /.+@.+\..+/.test(v) || 'E-mail не валідний',
            ],
            
        }),
        computed: {
            ...mapGetters({
            users_type: "users/users_type" 
            }),
            user_type_custom(){
                return this.users_type && this.users_type.filter(x => x.id != 1) || []
            }
        },
        mounted(){

        },
        methods: {
            show(item){
                //console.log(order);
                this.dialog = true;
                this.userData = JSON.parse(JSON.stringify(this.defaultUserData.change));
                this.changeForm = true;
                this.form_data = JSON.parse(JSON.stringify(item));
            },
            validate () {
                if (this.$refs.form.validate()) {
                this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.changeForm = false
                this.userData = JSON.parse(JSON.stringify(this.defaultUserData.add));
            },
            sendForm() {
                //console.log(this.form_data);
                
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PUT",
                        url: `/api/user/change_status_type/${this.form_data.profile_id}/`,
                        data: {user_type : this.form_data.user_type},
                    })
                    .then(() => {
                        //console.log(response);
                        this.loadingSubmit = false;
                        this.$emit('add-change-user');
                        this.$router.app.$sheet.show(this.userData.message);
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.loadingSubmit = false;
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    });
                }
                
            }
        }
    }
</script>