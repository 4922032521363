<template>
  
  <v-container >
    <v-row justify="center" class="mt-lg-6">
      <v-col  md="12" lg="10" xl="8">
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема даних для відображення"
          :items="districts"
          :options.sync="options"
          :loading="loading"
          :footer-props="footer"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>

            <v-toolbar flat :color="$vuetify.theme.dark ? 'background2' : 'white'">
              <v-toolbar-title><h2 class="mr-6">Дільниці</h2></v-toolbar-title>
            
              <v-spacer></v-spacer>
              
              <AddDistrictModal ref="addDisrict" :user="user" :operators_groups="operators_groups"/>

            </v-toolbar>
            
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ districts.indexOf(item) + 1 }}</span>
        </template>
        <template #item.operator_group="{ item }">
          {{ operators_groups.find(x => x.id == item.operator_group).name }}
        </template>
        <template #item.options="{ item }">
          <v-btn title="Редагувати" icon  @click="$refs.addDisrict.show(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn title="Видалити" icon @click="$refs.confirmDeleteDistrict.show(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
        </template>
        </v-data-table>
      </v-col>
      </v-row>
      <ModalConfirmDeleteDistrict ref="confirmDeleteDistrict" />
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex"
  export default {
    name: "table-districts",
    props: ["user"],
    comments:{
    },
    data: () => ({
      updateTable: 0,
      loading: false,
      options: {},
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        { text: "Назва", value: 'name', sortable: false, },
        { text: "Оператор", value: 'operator_group', sortable: false, },
        { text: 'Опції', value: 'options', sortable: false, }, 
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    computed: {
        ...mapGetters({
          districts: 'filters/districts',
          operators_groups: 'filters/operators_groups'
        }),
    },
    mounted(){
      if(this.user?.operator_id == 1){ // Якщо оператор "Altair Group"
        //
      }else{
        this.headers.splice(2, 1)
      }
    },
    watch: {
      options: {
        handler () {
          this.getDistricts();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getDistricts();
      },
    },
    methods: {
      getDistricts(){
        this.$store.dispatch('filters/fetchDistricts');
      },
    },
  }
</script>